import styled, {css} from 'styled-components'
import React from "react";

const withPadding = css`
  //padding: 1.8rem; //shopify
  padding: 16px; //ecwind
  
  @media screen and (min-width: 768px){
    padding: 20px 24px;
  }
`;

const CardStyled = styled.div`
    box-sizing: border-box;
    margin-bottom: 16px;
    padding: 0;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 0 25px 0 rgba(0,0,0,.05);
    transition: box-shadow .2s linear, border-color .2s linear, background-color .2s linear;
    
    ${({ usepadding }) => (usepadding ? withPadding : '')}
`
export const CardHeader = styled.div`
  padding: 16px;
  border-bottom: 0.5px solid rgb(234, 234, 234);
`
export const CardBody = styled.div`
  padding: 16px;
`
const Card = ({padding = false, style, children, onClick}) => {
  return (
    <CardStyled style={style} usepadding={padding} onClick={onClick}>
      {children}
    </CardStyled>    
  )
}

export default Card

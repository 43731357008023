import {useRef} from 'react';
import useToggle from '../hooks/useToggle';

const useDeleteModal = ({text, action = () => {}, onRefresh = () => {}}) => {
	const id = useRef(null);
	const {isOpen, toggler} = useToggle({
		onOpen: (currentId) => (id.current = currentId),
		onClose: onRefresh
	});

	const openDeleteModal = (id) => toggler(id);

	const executeAction = () => {
		action(id.current)
			.then(() => {
				toggler();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const wrapper = {
		show: isOpen,
		text: text,
		onHide: toggler,
		action: executeAction
	};

	return {wrapper, refresh: false, id, openDeleteModal, toggler};
};

export default useDeleteModal;

import React from "react";
import styled from "styled-components";

const PageTitleSpan = styled.h5`
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  transition: 0.5s all ease-in-out;

  @media screen and (min-width: 768px) {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 1.5rem;
    transition: 0.5s all ease-in-out;
  }
`;

// TODO :  Evaluate if icon is needed
const PageTitle = ({title, icon}) => {
  return (
    <PageTitleSpan>
      {title}
      {icon}
    </PageTitleSpan>
  );
};


export default PageTitle;



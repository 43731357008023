import {Route, Routes} from "react-router";
import './App.css';
import DeletePage from "./pages/DeletePage";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ActionContextWrapper from "./context/ActionContextWrapper";

toast.configure()

function App() {
  return (
    <ActionContextWrapper>
      <Routes>
        <Route path="/" element={<DeletePage/>}/>
      </Routes>
    </ActionContextWrapper>
  );
}

export default App;

import {toast} from "react-toastify";


const toastSucessStyle={
  backgroundColor:"#4fb849",
  width:"420px",
  right:"100px",
  fontSize:"18px"
}

const toastErrorSucessStyle={
  backgroundColor:"#d24250",
  width:"420px",
  right:"100px",
  fontSize:"18px"
}


const successToast= (text = "Se ha guardado correctamente",time=2000)=>{
  return(
    toast.success(text, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: time,
      draggable: true,
      style: toastSucessStyle
    })
  )
}

const errorToast=(text="Ocurrió un problema al guardar. Intente de nuevo en unos minutos",time=5000)=>{
  toast.error(text, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: time,
    style: toastErrorSucessStyle
  })
}

export {toastErrorSucessStyle,toastSucessStyle,successToast,errorToast};

import {useState} from 'react';

const useToggle = ({onOpen = () => {}, onClose = () => {}}) => {
	const [isOpen, setIsOpen] = useState(false);
	const toggler = (props) => {
		const nextIsOpen = !isOpen;
		setIsOpen(nextIsOpen);
		if (nextIsOpen) {
			onOpen(props);
		} else {
			onClose(props);
		}
	};

	return {isOpen, toggler};
};

export default useToggle;

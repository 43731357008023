import styled from "styled-components";

const PageSection = styled.div`
  margin: 16px 8px;
  padding-bottom:16px;
  transition: 0.5s all ease-in-out;

  @media screen and (min-width: 992px){
    transition: 0.5s all ease-in-out;
    margin: 22px 22px;
  } 
`;

export default PageSection;
